import React from "react"
import SEO from "../../components/seo"
import { Link } from "gatsby"
import CategoryItems from "../../components/CategoryItems"
import CollectionTemplate from "../../templates/CollectionTemplate"

const BraceletsPage = () => (
  <CollectionTemplate
    collectionTitle="BRACELETS"
    classExtra="ringscollectionpage"
  >
    <SEO
      title="Bracelets"
      keywords={[
        `exclusive`,
        `australian`,
        `bracelets`,
        `engagement`,
        `wedding`,
      ]}
    />
    <div className="productnavbar">
      <Link className="linkspacer" to="/collection">
        all
      </Link>{" "}
      <div className="linkspacer">/</div>
      <Link className="linkspacer" to="/collection/rings">
        rings
      </Link>
      <div className="linkspacer">/</div>
      <Link className="linkspacer" to="/collection/decollete">
        decollete
      </Link>
      <div className="linkspacer">/</div>
      <Link className="linkspacer" to="/collection/earrings">
        earrings
      </Link>
      <div className="linkspacer">/</div>
      <Link className="linkspacer" to="/collection/homme">
        homme
      </Link>
      <div className="linkspacer">/</div>
      <Link className="linkspacer" to="/collection/bespoke">
        bespoke
      </Link>
    </div>
    <div className="SEOContainer">
      {/* <div className="PicContain">
          <div className="SEOBespoke" />
        </div> */}
      <div className="SEOText">
        Although this collection is small, it sure is mighty! Our bracelets
        feature art deco design elements such as bezel set gemstones, milgrain
        detailing and well balanced vintage lines. If you’re looking for a
        luxury gift, a treat to spoil yourself or a breathtaking ‘something
        blue’ for your wedding, we’ve got it here. Want to create something
        completely unique? Contact us to begin your bespoke journey!
      </div>
    </div>
    <div id="scroll" className="storewrap">
      <CategoryItems category="bracelets" />
    </div>
  </CollectionTemplate>
)

export default BraceletsPage
